<template>
  <section class="reset-password-form  pt-l pb-l">
    <div class="site-inner">
      <div class="row">
        <div class="col-12  col-offset-6  col-lg-20  col-lg-offset-1" v-if="!success && !error">
          <h4 class="h4">{{ $t('resetPasswordForm.headline') }}</h4>
          <FormElement @submit="submitHandler">
            <input type="hidden" name="token" v-model="token">
            <InputText name="password"
                       base="resetPasswordForm"
                       type="password"
                       :class="['mb-s', { '-has-error': $v.password.$error }]"
                       v-model.trim="$v.password.$model">
              <PasswordStrength :pwScore="pwScore"></PasswordStrength>
              <template v-slot:errors>
                <template v-if="$v.password.$error">
                  <template v-if="!$v.password.required">
                    {{ $t('signUpForm.inputs.password.error.required') }}
                  </template>
                  <template v-else-if="!$v.password.minLength">
                    {{ $t('signUpForm.inputs.password.error.minLength') }}
                  </template>
                  <template v-else-if="!$v.password.minPasswordStrength">
                    {{ $t('signUpForm.inputs.password.error.minPasswordStrength') }}
                  </template>
                  <template v-else-if="!$v.password.maxLength">
                    {{ $t('signUpForm.inputs.password.error.maxLength') }}
                  </template>
                  <template v-else>
                    {{ $t('signUpForm.inputs.password.error.default') }}
                  </template>
                </template>
              </template>
            </InputText>
            <InputText name="confirmPassword"
                       base="resetPasswordForm"
                       type="password"
                       :class="{ '-has-error': $v.confirmPassword.$error}"
                       v-model.trim="$v.confirmPassword.$model">
            </InputText>
            <Submit class="-blue  mt-s">{{ $t('resetPasswordForm.inputs.submit') }}</Submit>
          </FormElement>
        </div>

        <div class="col-12  col-offset-6  col-lg-20  col-lg-offset-1" v-if="success">
          <h4 class="h4">{{ $t('resetPasswordForm.successful') }}</h4>
          <i18n path="resetPasswordForm.redirect" tag="p">
            <template v-slot:delay>
              <span>{{  $tc('units.seconds', delay / 1000) }}</span>
            </template>
            <template v-slot:loginLink>
              <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }">{{ $t('global.login.label') }}</router-link>
            </template>
          </i18n>
        </div>

        <div class="col-12  col-offset-6  col-lg-20  col-lg-offset-1" v-if="error">
          <h4 class="h4">{{ $t('resetPasswordForm.error') }}</h4>
          <p>
            {{ errorMessage }}
            <router-link class="link  -orange" :to="{ name: 'RequestPassword', params: { lang: $i18n.locale } }">{{ $t('resetPasswordForm.requestLink') }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';

import {
  required,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import { resetPassword } from '@/api/password.api';
import pwScore from '@/mixins/pwScore';

import Submit from '@/components/atoms/Submit.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import PasswordStrength from '@/components/elements/PasswordStrength.vue';

export default {
  name: 'ResetPasswordForm',

  props: {
    token: String,
  },

  data() {
    return {
      confirmPassword: '',
      delay: 3000,
      error: null,
      password: '',
      success: false,
    };
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
      minPasswordStrength: function minPwStr(password) {
        return this.minPasswordStrength(password);
      },
    },
    confirmPassword: { required, sameAs: sameAs('password') },
  },

  computed: {
    errorMessage() {
      return (this.error) ? this.$t(`resetPasswordForm.errors.${this.error}`) : '';
    },
  },

  methods: {
    async submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const response = await resetPassword(this.token, this.password);
      if (!response.error) {
        this.success = true;
        this.timeout = Date.now() + this.delay;
        window.setTimeout(() => {
          this.$router.push({ name: 'Login', params: { lang: this.$i18n.locale } });
        }, this.delay);
      } else {
        this.error = response.error;
      }
    },
  },
  mixins: [validationMixin, pwScore],
  components: {
    Submit,
    FormElement,
    InputText,
    PasswordStrength,
  },
};
</script>
