import httpClient from './httpClient';

const requestNewPassword = (email) => httpClient.post('/request-password', { email });

const resetPassword = async (token, password) => {
  try {
    return await httpClient.post('/reset-password', { token, password });
  } catch (error) {
    if (error.response.status === 403) {
      return { error: 'token_expired' };
    }

    if (error.response.status === 400) {
      return { error: 'token_invalid' };
    }

    return { error: 'general' };
  }
};

export {
  requestNewPassword,
  resetPassword,
};
