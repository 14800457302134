<template>
  <div>
    <FormElement @submit="submitHandler">
      <Modal ref="modal" :defaultOpen="true" :title="$t('acceptTermsForm.title')">

        <span v-html="$t('acceptTermsForm.paragraph')"></span>

        <template v-if="$t('acceptTermsForm.changes').length > 0">
          <p class="text-mt-m-less">{{ $t('acceptTermsForm.changesIntro') }}</p>

          <ul>
            <li v-for="(change, idx) in $t('acceptTermsForm.changes')" :key="idx">{{ change }}</li>
          </ul>
        </template>

        <hr class="-grey-100" />
        <InputCheckbox base="acceptTermsForm" name="consentAgb" id="consentAgb" v-model="$v.consentAgb.$model" :class="{ '-has-error': isInvalid }" />

        <template v-slot:footer>
          <div class="display-flex justify-content-end">
            <Submit :class="{'-orange': !$v.$invalid}">{{ $t('acceptTermsForm.inputs.submit') }}</Submit>
          </div>
        </template>
      </Modal>
    </FormElement>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { sameAs } from 'vuelidate/lib/validators';
import { updateUser } from '@/api/users.api';
import FormElement from '@/components/elements/FormElement.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import Submit from '@/components/atoms/Submit.vue';
import Modal from '@/components/elements/Modal.vue';

export default {
  name: 'AcceptTermsForm',
  data() {
    return {
      consentAgb: false,
      isInvalid: false,
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  validations: {
    consentAgb: { sameAs: sameAs(() => true) },
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['updateUser']),

    async submitHandler() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = { consentAgb: true, consentPrivacy: true };
        this.updateUser(payload);
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            updateUser(this.user.uuid, payload, token)
              .then(() => {
                this.$emit('update');
                this.$refs.modal.close(true);
                this.$eventBus.$emit('notificate');
                this.redirect();
              })
              .catch((error) => {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              });
          });
      } else {
        this.isInvalid = true;
      }
    },

    redirect() {
      const { redirect } = this.$route.query;
      if (redirect) {
        this.$router.push({ path: redirect, params: this.$route.params });
      } else {
        this.$router.push({ name: 'Application', params: this.$route.params });
      }
    },
  },
  mixins: [validationMixin],
  components: {
    FormElement,
    InputCheckbox,
    Submit,
    Modal,
  },
};
</script>
<style lang="scss" src="@/sass/06_atoms/link.scss"></style>
