<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="reset-password">
      <ResetPasswordForm :token="token" />
    </main>
  </div>
</template>

<script>
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';
import ResetPasswordForm from '@/components/modules/ResetPasswordForm.vue';

export default {
  name: 'ResetPassword',

  props: {
    token: {
      type: String,
      default: null,
    },
  },

  created() {
    if (this.token === null) {
      this.$router.push({ name: 'RequestPassword', params: { lang: this.$i18n.locale } });
    }
  },

  components: {
    HeaderBarPublic,
    ResetPasswordForm,
  },
};
</script>
