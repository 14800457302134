<template>
  <div class="set-password-form  pt-l  pb-l">
    <FormElement @submit="submitHandler">
      <template v-if="!success">
        <h1 class="h2 font-weight-normal">
          <template v-if="formalTitle && lastname">
            {{ $t('setPassword.headline') }}, {{ $t(`setPassword.formalTitle.${formalTitle}`) }} {{ lastname }}
          </template>
          <template v-else>
            {{ $t('setPassword.headline') }}
          </template>
        </h1>
        <p class="font-weight-medium  mb-s">{{ $t('setPassword.paragraph') }}</p>

        <InputText name="email"
                   base="setPassword"
                   type="email"
                   :class="{ '-has-error': $v.email.$error}"
                   :disabled="!!predefinedEmail"
                   v-model.trim="$v.email.$model">
        </InputText>

        <div v-if="error">
          {{ error.status }}
          {{ error.message }}
        </div>

        <div class="text-right">
          <Submit class="-orange  mt-s">{{ $t('setPassword.submit') }}</Submit>
        </div>
      </template>
      <template v-if="success">
        <h4 class="h4">{{ $t('setPassword.success.headline') }}</h4>
        <i18n path="setPassword.success.paragraph" tag="p">
          <template v-slot:email>
            <span class="font-weight-semibold  color-brand-orange">{{ email }}</span>
          </template>
        </i18n>
      </template>
    </FormElement>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { requestNewPassword } from '@/api/password.api';

import Submit from '@/components/atoms/Submit.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';

export default {
  name: 'SetPasswordForm',
  props: {
    predefinedEmail: String,
    formalTitle: String,
    lastname: String,
  },
  data() {
    return {
      email: this.predefinedEmail || '',
      success: false,
      error: null,
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    async submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const result = await requestNewPassword(this.email);

        if (result.status === 200) {
          this.success = true;
        }
      } catch (error) {
        this.error = { status: error.response.status, message: error.response.message };
      }
    },
  },
  mixins: [validationMixin],
  components: {
    Submit,
    FormElement,
    InputText,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/set-password-form.scss"></style>
