<template>
  <div class="site">
    <main class="accept-terms">
      <div
        class="-full-height"
        :style="{
          backgroundImage: 'url(' + require('@/assets/graphics/accept-terms-bg.jpg') + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }"
      ></div>
      <AcceptTermsForm />
    </main>
  </div>
</template>

<script>
import AcceptTermsForm from '../components/modules/AcceptTermsForm.vue';

export default {
  name: 'AcceptTerms',
  components: {
    AcceptTermsForm,
  },
  metaInfo() {
    return {
      title: 'Login',
    };
  },
};
</script>
