<template>
  <section class="request-password-form  pt-l  pb-l">
    <div class="site-inner">
      <FormElement @submit="submitHandler">
        <div class="row">
          <div class="col-12  col-offset-6  col-lg-20  col-lg-offset-1" v-if="!success">
            <h4 class="h4">{{$t('requestPasswordForm.headline') }}</h4>
            <p class="mb-m">{{ $t('requestPasswordForm.explanation') }}</p>

            <InputText name="email"
                       base="loginForm"
                       type="email"
                       :class="{ '-has-error': $v.email.$error}"
                       v-model.trim="$v.email.$model">
            </InputText>

            <div v-if="error">
              {{ error.status }}
              {{ error.message }}
            </div>

            <Submit class="-blue  mt-s">{{ $t('requestPasswordForm.inputs.submit') }}</Submit>
          </div>
          <div class="col-12  col-offset-6" v-if="success">
            <h4 class="h4">{{ $t('requestPasswordForm.successful') }}</h4>
            <p>{{ $t('requestPasswordForm.emailSent') }}</p>
          </div>
        </div>
      </FormElement>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { requestNewPassword } from '@/api/password.api';

import Submit from '@/components/atoms/Submit.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';

export default {
  name: 'RequestPasswordForm',
  data() {
    return {
      email: '',
      success: false,
      error: null,
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    async submitHandler() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const result = await requestNewPassword(this.email);

        if (result.status === 200) {
          this.success = true;
        }
      } catch (error) {
        this.error = { status: error.response.status, message: error.response.message };
      }
    },
  },
  mixins: [validationMixin],
  components: {
    Submit,
    FormElement,
    InputText,
  },
};
</script>
