<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main>
      <div v-if="$matchMedia.sm"
           :style="{marginTop: '-40px',height: '40vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%',
           backgroundImage: 'url('+require('@/assets/graphics/login.jpg')+')'}"></div>
      <TwoColumns image="login">
        <template v-slot:text-content>
          <div class="row  mb-m">
            <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1">
              <SetPasswordForm :predefined-email="email"
                               :formal-title="formalTitle"
                               :lastname="lastname">
              </SetPasswordForm>
            </div>
          </div>
        </template>
        <template v-slot:image-content></template>
      </TwoColumns>
    </main>
  </div>
</template>

<script>
import TwoColumns from '@/components/elements/TwoColumns.vue';
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';
import SetPasswordForm from '@/components/modules/SetPasswordForm.vue';

export default {
  name: 'SetPassword',
  props: {
    email: String,
    formalTitle: String,
    lastname: String,
  },
  components: {
    TwoColumns,
    HeaderBarPublic,
    SetPasswordForm,
  },
};
</script>
