<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="request-password">
      <RequestPasswordForm />
    </main>
  </div>
</template>

<script>
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';
import RequestPasswordForm from '@/components/modules/RequestPasswordForm.vue';

export default {
  name: 'RequestPassword',
  components: {
    HeaderBarPublic,
    RequestPasswordForm,
  },
};
</script>
